import * as React from 'react';
import Script from 'next/script';

export default function Addons ( props ) {
  return (
    <>
      <Script src={'https://kit.fontawesome.com/3c7cbada9c.js'} crossOrigin={'anonymous'} async
        // nonce="nx4IO48ELrYf7R9HSISwzINaRh3Y6KLCVWVO149aePU="
      />
      {
        props.hasOwnProperty( 'script_sections' ) ?
          props.script_sections != null ?
            props.script_sections.map( ( section, key ) => (
              // section.app_section_content
              section.app_section_content != null ?
                !section.app_section_content.includes( 'platform' ) ?
                  section.app_section_content.split( '<script>' )[1] != null ?
                    <script
                      key={key}
                      src={section.app_section_content.split( '<script>' )[1].split( '</script>' )[0]}
                      defer
                    />
                    :''
                  :''
                :''
            ) )
            :''
          :''
      }
    </>
  )
}